// import { slick } from './_slick.js';
import { ham } from './_ham.js';
import { aLink } from './_a-link.js';
import { swiper } from './_swiper.js';
import { modal } from './_modal.js';

// slick();
ham();
aLink();
swiper();
modal();