export function aLink() {

  // var headerHeight = $('header').outerHeight();
  $('a[href^="#"]').click(function() {
      var href= $(this).attr("href");
      var target = $(href);
      // var position = target.offset().top - headerHeight;
      var position = target.offset().top;
      $('body,html').stop().animate({scrollTop:position}, 600);   
      return false;
  });

}