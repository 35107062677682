export function ham() {
	let scrollPosition = 0;

  /*! ハンバーガーメニュー */
  $(function () {
    // ハンバーガーメニューの開閉
    $('.l-ham').on("click", function (event) {
      event.preventDefault(); // デフォルトの動作を防ぐ
      $('.l-ham__nav').toggleClass('open');
      $(this).toggleClass('open');

      if ($(this).hasClass('open')) {
        scrollPosition = $(window).scrollTop();
        $('body').addClass('scroll-prevent').css({
          'top': -scrollPosition
        });
      } else {
        $('body').removeClass('scroll-prevent').css({
          'top': ''
        });
        $(window).scrollTop(scrollPosition);
      }
    });

    // ナビゲーションリンクがクリックされたときにメニューを閉じる
    $('.l-ham__nav a').on("click", function (event) {
      if ($('.l-ham__nav').hasClass('open')) { // .l-ham__navにopenクラスが付いているときのみ動作
        var href = $(this).attr('href');
        if (href.startsWith('#')) {
          event.preventDefault(); // デフォルトのリンク動作を無効化
          $('.l-ham__nav').removeClass('open');
          $('.l-ham').removeClass('open');
          $('body').removeClass('scroll-prevent').css({
            'top': ''
          });
          $(window).scrollTop(scrollPosition);

          // スムーズスクロール
          var targetId = href.substring(1); // リンク先のIDを取得
          var targetElement = $('#' + targetId);
          if (targetElement.length) {
            $('html, body').stop().animate({
              scrollTop: targetElement.offset().top
            }, 500); // 500ミリ秒でスムーズにスクロール
          }
        }
      }
    });
  });
}