export function swiper() {
  /*! TOPスライド */
  const swiperTop = new Swiper(".swiper", {
    loop: true, // ループさせる
    speed: 1500, // 少しゆっくり(デフォルトは300)
    effect: 'fade', // フェード
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });
}