export function modal() {
  jQuery(document).ready(function($){
    // モーダル表示判別フラグ
    var modal_show_flg = false;
    
    // モーダルオープン
    $('.js-modal-open').on('click', function(e) {
      e.preventDefault();
      var targetModal = $(this).data('modal');
      var modal = $('#' + targetModal);
      modal.fadeIn();
      bodyFix();
      setTimeout(function() {
        modal_show_flg = true;
      }, 10);
    });
    
    // モーダルクローズ
    $('.modal-btn-close').on('click', function() {
      modalClose($(this).closest('.modal'));
    });
    $(document).on('click', function(e) {
      var target = $(e.target);
      if(modal_show_flg && !target.parents('.modal-content-box').length) {
        modalClose(target.closest('.modal'));
        return false;
      }
      if(modal_show_flg && target.hasClass('modal-content-box')) {
        modalClose(target.closest('.modal'));
        return false;
      }
    });
    // escキーでモーダルクローズ
    $(window).keyup(function(e){
      if(e.keyCode == 27){
        if(modal_show_flg) {
          modalClose($('.modal:visible'));
        }
      }
    });
    // モーダルクローズ関数
    function modalClose(modal) {
      bodyFixReset();
      modal.fadeOut();
      modal_show_flg = false;
    }
    
    // body固定関数
    var bodyElm = $('body');
    var scrollPosi;
    function bodyFix() {
      scrollPosi = $(window).scrollTop();
      bodyElm.css({
        'position': 'fixed',
        'width': '100%',
        'z-index': '1',
        'top': -scrollPosi
      });
    }
    
    // body fixリセット
    function bodyFixReset() {
      bodyElm.removeAttr('style');
      // scroll位置を調整
      $("html, body").scrollTop(scrollPosi);
    }
  });
}